<template>
  <section
    id="licence_overview"
    class="grid-zone"
  >
    <div id="block_left">
      <figure id="illustration">
        <img
          :src="product.url_couverture
            || require('@lde/core_lde_vue/dist/assets/media/missing_img/no_img_numerique.svg')"
          :alt="$t('general.alt-image-produit')"
          @error="product.url_couverture = null"
        />
      </figure>
      <div v-tooltip="{ content: tooltip(product), }">
        <ButtonClassic
          v-if="totaux.eleve || totaux.enseignant"
          variant="special"
          :label="$t('licence.demande-licences-enseignant-supplementaires')"
          color="primary"
          icon="right"
          :disabled="product.demandes.length || !canOrderLicence"
          @click="$modal.show('modal_ask_licences')"
        >
          <template #right-icon>
            <UilCommentDots />
          </template>
        </ButtonClassic>
      </div>
    </div>

    <div id="block_right">
      <h2 class="text-regular bold">
        {{ $t("licence.vous-avez-achete") }}
      </h2>
      <table class="text-medium">
        <thead>
          <tr>
            <th
              v-for="item in tableHeader"
              :key="item.key"
            >
              <button
                class="button-filter"
                @click="sortLicences(item.key)"
              >
                <UisSorting size="16" />
                <span class="text-small">{{ item.name }}</span>
              </button>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="offre in product.offres_commandees"
            :key="offre.id"
            :class="{echue: offre.mois_restants !== null && offre.mois_restants <= 0, }"
          >
            <td>
              <span
                class="circle"
                :class="{
                  green: offre.mois_restants === null || offre.mois_restants > 3,
                  orange: offre.mois_restants !== null && offre.mois_restants > 1 && offre.mois_restants <= 3,
                  red: offre.mois_restants !== null && offre.mois_restants <= 1,
                }"
              />
            </td>
            <td :class="{ 'text-red': offre.mois_restants !== null && offre.mois_restants <= 1, }">
              <router-link :to="{ name: 'commandes_factures_commandes_item', params: { id: offre.commande, }, }">
                {{ offre.type_verbose }}
              </router-link>
            </td>
            <td :class="{ 'text-red': offre.mois_restants !== null && offre.mois_restants <= 1, }">
              {{ offre.date_commande | moment($t("global.format-date")) }}
            </td>
            <td
              :class="{
                'text-orange': offre.mois_restants !== null && offre.mois_restants > 1 && offre.mois_restants <= 3,
                'text-red': offre.mois_restants !== null && offre.mois_restants <= 1,
              }"
            >
              {{ offre.date_echeance | moment($t("global.format-date")) }}
            </td>
            <td :class="{ 'text-red': offre.mois_restants !== null && offre.mois_restants <= 1, }">
              {{ offre.nb_licences_eleve }}
            </td>
            <td :class="{ 'text-red': offre.mois_restants !== null && offre.mois_restants <= 1, }">
              {{ offre.nb_licences_enseignant }}
            </td>
          </tr>
        </tbody>
        <tfoot class="text-regular">
          <tr>
            <td colspan="4">
              {{ $t("licence.nombre_total_licences_actives") }}
            </td>
            <td>{{ totaux.eleve }}</td>
            <td>{{ totaux.enseignant }}</td>
          </tr>
        </tfoot>
      </table>
    </div>
    <ModalAskLicences
      :lignes="product.offres"
      :nb-licences-offertes="product.nb_licences_deja_offertes"
    />
  </section>
</template>

<script>
import { ButtonClassic } from "@lde/core_lde_vue";

import ModalAskLicences from "@/components/modals/ModalAskLicences.vue";

import mixinLicences from "@/mixins/mixinLicences";

import { mapGetters } from "vuex";

import { UilCommentDots } from "@iconscout/vue-unicons";
import { UisSorting } from "@iconscout/vue-unicons-solid";

/**
 * Affiche les informations de base du produit avec les licences disponibles.
 */
export default {
  name: "LicenceOverview",
  components: {
    ButtonClassic,
    ModalAskLicences,
    UilCommentDots,
    UisSorting,
  },
  mixins: [mixinLicences],
  props: {
    /**
     * Information du produit.
     */
    product: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      tableHeader: [
        { key: "mois_restants", name: this.$t("general.statut") },
        { key: "type_verbose", name: this.$t("licence.type-licence") },
        { key: "date_commande", name: this.$tc("commande.commande") },
        { key: "date_echeance", name: this.$t("general.echeance") },
        { key: "nb_licences_eleve", name: this.$tc("produit.licence-eleve") },
        { key: "nb_licences_enseignant", name: this.$tc("licence.licence-enseignant") },
      ],
      lastSort: {},
    };
  },
  computed: {
    ...mapGetters(["compatibleAdoptant"]),
    totaux() {
      const res = {
        eleve: 0,
        enseignant: 0,
      };

      this.product.offres_commandees.forEach((offre) => {
        if (offre.mois_restants > 0) {
          res.eleve += offre.nb_licences_eleve || 0;
          res.enseignant += offre.nb_licences_enseignant || 0;
        }
      });

      return res;
    },
    nomDemande() {
      if (!this.product.demandes.length) {
        return "";
      }

      const firstDemandContact = this.product.demandes[0].utilisateur.contact;

      return `${firstDemandContact.prenom} ${firstDemandContact.nom}`;
    },
    dateDemande() {
      if (!this.product.demandes.length) {
        return "";
      }

      return this.$moment(this.product.demandes[0].date).format(this.$t("global.format-date"));
    },
  },
  mounted() {
    this.product.offres_commandees.forEach((offre) => {
      if (this.compatibleAdoptant) {
        this.$set(offre, "type_verbose", this.$t("produit.licence-x", { duree: offre.duree_verbose }));
      } else {
        this.$set(offre, "type_verbose", (
          offre.adoptant
            ? this.$t("produit.licence-x-adoptant", { duree: offre.duree_verbose })
            : this.$t("produit.licence-x-non-adoptant", { duree: offre.duree_verbose })
        ));
      }
    });
  },
  methods: {
    /**
     * Trie les éléments du tableau.
     * @param {String} sortKey Nom du champ sur lequel trier les licences commandées.
     */
    sortLicences(sortKey) {
      if (this.lastSort.key !== sortKey) {
        // Si le dernier tri n'est pas sur la même colonne, on trie par défaut
        this.product.offres_commandees.sort((a, b) => {
          if (a[sortKey] < b[sortKey]) return -1;
          if (a[sortKey] > b[sortKey]) return 1;
          return 0;
        });
        this.lastSort.asc = true;
      } else {
        // Si le dernier tri est sur la même colonne, on effectue l'inverse du précédent tri
        this.product.offres_commandees.reverse();
        this.lastSort.asc = !this.lastSort.asc;
      }
      this.lastSort.key = sortKey;
    },
  },
};
</script>

<style lang="scss">
@use "@/assets/styles/components/licences/licence_overview.scss";
</style>
