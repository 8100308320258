var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"grid-zone",attrs:{"id":"licence_overview"}},[_c('div',{attrs:{"id":"block_left"}},[_c('figure',{attrs:{"id":"illustration"}},[_c('img',{attrs:{"src":_vm.product.url_couverture
          || require('@lde/core_lde_vue/dist/assets/media/missing_img/no_img_numerique.svg'),"alt":_vm.$t('general.alt-image-produit')},on:{"error":function($event){_vm.product.url_couverture = null}}})]),_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({ content: _vm.tooltip(_vm.product), }),expression:"{ content: tooltip(product), }"}]},[(_vm.totaux.eleve || _vm.totaux.enseignant)?_c('ButtonClassic',{attrs:{"variant":"special","label":_vm.$t('licence.demande-licences-enseignant-supplementaires'),"color":"primary","icon":"right","disabled":_vm.product.demandes.length || !_vm.canOrderLicence},on:{"click":function($event){return _vm.$modal.show('modal_ask_licences')}},scopedSlots:_vm._u([{key:"right-icon",fn:function(){return [_c('UilCommentDots')]},proxy:true}],null,false,1288914817)}):_vm._e()],1)]),_c('div',{attrs:{"id":"block_right"}},[_c('h2',{staticClass:"text-regular bold"},[_vm._v(" "+_vm._s(_vm.$t("licence.vous-avez-achete"))+" ")]),_c('table',{staticClass:"text-medium"},[_c('thead',[_c('tr',_vm._l((_vm.tableHeader),function(item){return _c('th',{key:item.key},[_c('button',{staticClass:"button-filter",on:{"click":function($event){return _vm.sortLicences(item.key)}}},[_c('UisSorting',{attrs:{"size":"16"}}),_c('span',{staticClass:"text-small"},[_vm._v(_vm._s(item.name))])],1)])}),0)]),_c('tbody',_vm._l((_vm.product.offres_commandees),function(offre){return _c('tr',{key:offre.id,class:{echue: offre.mois_restants !== null && offre.mois_restants <= 0, }},[_c('td',[_c('span',{staticClass:"circle",class:{
                green: offre.mois_restants === null || offre.mois_restants > 3,
                orange: offre.mois_restants !== null && offre.mois_restants > 1 && offre.mois_restants <= 3,
                red: offre.mois_restants !== null && offre.mois_restants <= 1,
              }})]),_c('td',{class:{ 'text-red': offre.mois_restants !== null && offre.mois_restants <= 1, }},[_c('router-link',{attrs:{"to":{ name: 'commandes_factures_commandes_item', params: { id: offre.commande, }, }}},[_vm._v(" "+_vm._s(offre.type_verbose)+" ")])],1),_c('td',{class:{ 'text-red': offre.mois_restants !== null && offre.mois_restants <= 1, }},[_vm._v(" "+_vm._s(_vm._f("moment")(offre.date_commande,_vm.$t("global.format-date")))+" ")]),_c('td',{class:{
              'text-orange': offre.mois_restants !== null && offre.mois_restants > 1 && offre.mois_restants <= 3,
              'text-red': offre.mois_restants !== null && offre.mois_restants <= 1,
            }},[_vm._v(" "+_vm._s(_vm._f("moment")(offre.date_echeance,_vm.$t("global.format-date")))+" ")]),_c('td',{class:{ 'text-red': offre.mois_restants !== null && offre.mois_restants <= 1, }},[_vm._v(" "+_vm._s(offre.nb_licences_eleve)+" ")]),_c('td',{class:{ 'text-red': offre.mois_restants !== null && offre.mois_restants <= 1, }},[_vm._v(" "+_vm._s(offre.nb_licences_enseignant)+" ")])])}),0),_c('tfoot',{staticClass:"text-regular"},[_c('tr',[_c('td',{attrs:{"colspan":"4"}},[_vm._v(" "+_vm._s(_vm.$t("licence.nombre_total_licences_actives"))+" ")]),_c('td',[_vm._v(_vm._s(_vm.totaux.eleve))]),_c('td',[_vm._v(_vm._s(_vm.totaux.enseignant))])])])])]),_c('ModalAskLicences',{attrs:{"lignes":_vm.product.offres,"nb-licences-offertes":_vm.product.nb_licences_deja_offertes}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }