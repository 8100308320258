<template>
  <div>
    <Modal
      id="modal_ask_licences"
      @change="!$event && hide()"
    >
      <template #title>
        {{ $t("licence.demande-licences-enseignant-supplementaires") }}
      </template>
      <template
        v-if="!showSuccessMsg"
        #subtitle
      >
        {{ $t("licence.cette-demande-envoyee-editeur") }}
      </template>
      <template #content>
        <template v-if="showSuccessMsg">
          <div class="text-regular success">
            <p>{{ $t("licence.demande-bien-pris-en-compte-nous-allons-prendre-contact-avec-editeur") }}</p>
            <p>{{ $t("licence.une-fois-livrees-retrouvez-licences") }}</p>
          </div>
        </template>
        <template v-else>
          <div
            v-if="formattedLines.length"
            class="text-regular body-content"
          >
            <p>{{ $t("licence.pour-l-offre") }}{{ "\xa0" }}:</p>
            <h3
              v-if="formattedLines.length === 1 && selectedLine"
              class="bold"
            >
              {{ selectedLine.label }}
            </h3>
            <SelectClassic
              v-else
              v-model="selectedLine"
              status="required"
              :options="formattedLines"
              :internal-search="false"
            >
              <template #label-icon>
                <UilBuilding size="16" />
              </template>
            </SelectClassic>

            <template v-if="selectedLine">
              <p v-if="nbLicencesOffertes > 0">
                {{ $tc(
                  "licence.x-licences-gratuites-livrees-suffisent-pas",
                  nbLicencesOffertes,
                  { nombre: nbLicencesOffertes, }
                ) }}
              </p>
              <p class="s5 light recap-nb-licences">
                {{ $t("general.nous-demandons") }}
                <InputQuantity
                  v-model="nbLicencesSupp"
                  :min="1"
                />
                {{ $tc("licence.licences-supp-titre-gracieux", nbLicencesSupp) }}
              </p>

              <div
                v-if="lignes.length"
                class="minima"
              >
                <p class="text-medium">
                  {{ $t('ask-licence.text-raison-licence-supp') }}{{ "\xa0" }}:
                </p>
                <ButtonToggle
                  v-model="selectedLine.raison"
                  name="effectif_reduit"
                  input-value="effectif_reduit"
                  :label="$t('ask-licence.raison-effectif')"
                />
                <ButtonToggle
                  v-model="selectedLine.raison"
                  name="plsr_enseignants"
                  input-value="plsr_enseignants"
                  :label="$t('ask-licence.raison-plsr-enseignants')"
                />
                <ButtonToggle
                  v-model="selectedLine.raison"
                  name="handicap"
                  input-value="handicap"
                  :label="$t('ask-licence.raison-handicap')"
                />
              </div>

              <p class="flex-vcenter">
                <span>{{ $t("general.en-tout") }},</span>
                <strong class="value">{{ totalCount | spaceNumber }}</strong>
                <span>{{ $tc("licence.enseignants-besoin-utiliser-ressource", totalCount) }}</span>
              </p>
            </template>
          </div>
        </template>
      </template>
      <template #footer>
        <ButtonClassic
          variant="solid"
          color="secondary"
          :label="showSuccessMsg ? $t('general.super') : $t('action.envoyer-ma-demande')"
          icon="right"
          :disabled="isLoading
            || !selectedLine
            || (selectedLine && !selectedLine.raison)"
          @click="submit()"
        >
          <template #right-icon>
            <UilThumbsUp v-if="showSuccessMsg" />
            <UilNavigator v-else />
          </template>
        </ButtonClassic>
      </template>
    </Modal>
  </div>
</template>

<script>
import {
  Modal,
  ButtonClassic,
  InputQuantity,
  ButtonToggle,
  SelectClassic,
} from "@lde/core_lde_vue";

import { UilNavigator, UilThumbsUp } from "@iconscout/vue-unicons";

import Api from "@/modules/axios";
import { confirmOrganismeOnTesting } from "@/modules/utils";

/**
 * Modale de demande de licences enseignant supplémentaires.
 */
export default {
  name: "ModalAskLicences",
  components: {
    Modal,
    ButtonClassic,
    InputQuantity,
    UilNavigator,
    ButtonToggle,
    SelectClassic,
    UilThumbsUp,
  },
  props: {
    /**
     * Informations relatives à la selectedLine.
     */
    lignes: {
      type: Array,
      default: () => [],
    },
    nbLicencesOffertes: {
      type: Number,
      default: 0,
    },
  },
  emits: [
    /**
     * Déclenché à l'envoi de la demande.
     */
    "submit",
  ],
  data() {
    return {
      nbLicencesSupp: 1,
      showSuccessMsg: false,
      isLoading: false,
      selectedLine: null,
    };
  },
  computed: {
    totalCount() {
      return this.nbLicencesSupp + this.nbLicencesOffertes;
    },
    formattedLines() {
      const formattedLines = this.lignes.map((ligne) => ({
        ...ligne,
        id: ligne.id,
        label: `${ligne.libelle} (${ligne.prix_editeur} €)`,
        value: ligne.id,
      }));

      return formattedLines;
    },
  },
  watch: {
    formattedLines: {
      deep: true,
      immediate: true,
      handler() {
        if (this.formattedLines.length === 1) {
          this.selectedLine = this.formattedLines[0];
        }
      },
    },
  },
  methods: {
    /**
     * Créer la licence enseignant.
     */
    createLicence() {
      this.isLoading = true;
      Api().post("/commande/create_licence_ens/", {
        lignes: [{
          ean_lde: this.selectedLine.ean_lde,
          ligne: this.selectedLine,
          quantite: this.nbLicencesSupp,
          raison: this.selectedLine.raison,
        }],
      })
        .then(() => {
          this.$toast.success({
            title: this.$t("toast.demande-licence-offerte-envoyee"),
            content: this.$t("toast.demande-licence-offerte-envoyee-content"),
          });
          this.$emit("submit", this.nbLicencesSupp);
          this.showSuccessMsg = true;
          this.selectedLine.demandes = [{
            utilisateur: this.user,
            date: new Date(),
          }];
        })
        .catch((err) => {
          this.$toast.error({ title: err.response?.data || this.$t("info.une-erreur-est-survenue") });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    /**
     * Vérifie si on peut créer la licence.
     */
    submit() {
      if (this.showSuccessMsg) {
        this.hide();
      } else if (confirmOrganismeOnTesting()) {
        this.createLicence();
      }
    },
    /**
     * Ferme la modale.
     */
    hide() {
      this.$modal.hide("modal_ask_licences");
    },
  },
};
</script>

<style lang="scss">
@use "@/assets/styles/components/modals/modal_ask_licences.scss";
</style>
